// extracted by mini-css-extract-plugin
export var border = "global-module--border--1b750";
export var button = "global-module--button--37034";
export var container = "global-module--container--ab0a8";
export var green = "global-module--green--4396d";
export var icon = "global-module--icon--798b2";
export var line = "global-module--line--85ffa";
export var loading = "global-module--loading--6e590";
export var margintop = "global-module--margintop--56f0a";
export var nonactive = "global-module--nonactive--4e079";
export var orange = "global-module--orange--c98bd";
export var section = "global-module--section--762f9";
export var transparent = "global-module--transparent--90571";
export var white = "global-module--white--579b5";