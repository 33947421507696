import React, { useState, lazy, useEffect, Suspense } from "react";
import { Link } from "gatsby";
import { useLocation } from "react-use";
import cx from "classnames";
import { useStaticQuery, graphql } from "gatsby";
import {
  IoMenu,
  IoCloseSharp,
  IoCall,
  IoArrowForwardSharp,
  IoArrowBackSharp,
} from "react-icons/io5";
import { AnimatePresence, motion } from "framer-motion";
import { useWindowScroll } from "react-use";

import Logo from "../images/svg/logo";

import * as global from "../styles/global.module.scss";
import * as styles from "./header.module.scss";

const Form = lazy(() => import("./form"));

function Header() {
  const [openNavMobile, setOpenNavMobile] = useState(false);

  const [callback, setCallback] = useState({
    title: "",
    description: "",
    name: "",
    open: false,
  });

  const { y } = useWindowScroll();

  const { pathname } = useLocation();

  const data = useStaticQuery(graphql`
    query {
      allOffersJson {
        nodes {
          slug
          name
        }
      }
    }
  `);

  const allPathnames = data.allOffersJson.nodes;
  const currentPathname = pathname && pathname.replace(/\//g, "");

  const isOffers = allPathnames.some((item) => item.slug === currentPathname);

  // const scrollToSection = (sectionId) => {
  //   const section = document.getElementById(sectionId);
  //   const offset = 100;
  //   const elementPosition = section.offsetTop - offset;
  //   window.scrollTo({
  //     top: elementPosition,
  //     behavior: "smooth",
  //   });
  // };

  useEffect(() => {
    if (openNavMobile || callback.open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [openNavMobile, callback.open]);

  return (
    <>
      <header className={cx(styles.container, y > 50 && styles.pressup)}>
        <nav className={styles.content}>
          <div className={styles.wrap}>
            <Link
              className={styles.home}
              to="/"
              aria-label="Логотип Мое пространство"
            >
              <Logo />
            </Link>

            {isOffers ? (
              <div className={styles.links}>
                {/* <div className={styles.item}>
                <Link to="/" className={cx(global.button, global.transparent)}>
                  <p>Главная</p>
                </Link>
              </div>
              <div className={styles.item}>
                <button
                  onClick={() => scrollToSection("about")}
                  className={cx(global.button, global.transparent)}
                  to="/"
                >
                  <p>О поселке</p>
                </button>
              </div>
              <div className={styles.item}>
                <button
                  onClick={() => scrollToSection("map")}
                  className={cx(global.button, global.transparent)}
                  to="/"
                >
                  <p>Расположение</p>
                </button>
              </div> */}
              </div>
            ) : (
              <div className={styles.links}>
                {/* <div className={styles.item}>
                <button
                  onClick={() => scrollToSection("offers")}
                  className={cx(global.button, global.transparent)}
                >
                  <p>Предложения</p>
                </button>
              </div>
              <div className={styles.item}>
                <button
                  onClick={() => scrollToSection("houses")}
                  className={cx(global.button, global.transparent)}
                  to="/"
                >
                  <p>Дома</p>
                </button>
              </div>
              <div className={styles.item}>
                <button
                  onClick={() => scrollToSection("projects")}
                  className={cx(global.button, global.transparent)}
                  to="/"
                >
                  <p>Реализованные проекты</p>
                </button>
              </div> */}
              </div>
            )}

            <div className={styles.contacts}>
              <div className={styles.item}>
                <a
                  className={cx(global.button, global.transparent)}
                  href="tel:+74950854858"
                >
                  +7 (495) 085-48-58
                </a>
              </div>

              <button
                className={cx(global.button, global.green)}
                onClick={() =>
                  setCallback({
                    id: "form_callback",
                    title: "Связаться с нами",
                    description:
                      "Наш специалист перезвонит и даст развернутую консультацию",
                    name: "Связаться",
                    open: true,
                  })
                }
              >
                <p>Связаться</p>
              </button>
            </div>

            <div className={styles.mobilenav}>
              <a
                href="tel:+74950854858"
                className={cx(global.button, global.green)}
              >
                <p>Связаться</p>
                <IoCall className={global.icon} />
              </a>

              <button
                className={cx(styles.menu, openNavMobile && styles.show)}
                onClick={() => setOpenNavMobile(!openNavMobile)}
              >
                <IoMenu className={styles.open} />
                <IoCloseSharp className={styles.close} />
              </button>
            </div>
          </div>
        </nav>
      </header>
      <AnimatePresence>
        {openNavMobile && (
          <motion.div
            animate={{
              y: 0,
              opacity: 1,
              transition: {
                duration: 0.4,
                ease: [0.24, 0.68, 0.79, 0.96],
              },
            }}
            initial={{ opacity: 0, y: 20 }}
            exit={{ opacity: 0 }}
            className={styles.mobiledetail}
          >
            <div className={styles.wrap}>
              <div className={styles.block}>
                <h2>Навигация</h2>
                <Link
                  className={styles.link}
                  activeClassName={styles.active}
                  to="/"
                  onClick={() => setOpenNavMobile(false)}
                >
                  <IoArrowForwardSharp className={styles.icon} />
                  <p>Главная страница</p>
                </Link>
                {data.allOffersJson.nodes.map((item, index) => {
                  const { name, slug } = item;

                  return (
                    <Link
                      className={styles.link}
                      activeClassName={styles.active}
                      to={`/${slug}`}
                      key={`link_${index}`}
                      onClick={() => setOpenNavMobile(false)}
                    >
                      <IoArrowForwardSharp className={styles.icon} />
                      <p>{name}</p>
                      <IoArrowBackSharp className={styles.icon} />
                    </Link>
                  );
                })}
              </div>
              <div className={styles.block}>
                <h2>Контакты:</h2>
                <a href="tel:+74950854858" className={styles.link}>
                  +7 (495) 085-48-58
                </a>
                <a
                  href="mailto:info@moe-prostranstvo.ru"
                  className={styles.link}
                >
                  info@moe-prostranstvo.ru
                </a>
                <a
                  href="https://m.vk.com/moepros"
                  className={styles.link}
                  id="link_vk"
                  target="_blank"
                  rel="noreferrer"
                >
                  Группа ВКонтакте
                </a>
              </div>
              <div className={styles.block}>
                <h2>Адрес</h2>
                <p>
                  г. Москва, пр. Серебрякова, 14, стр. 15
                  <br />
                  Ботанический сад
                </p>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <Suspense>
        {callback.open && (
          <Form
            slug={currentPathname === "" ? "main" : currentPathname}
            callback={callback}
            setCallback={setCallback}
            close={() =>
              setCallback({
                title: "",
                description: "",
                name: "",
                open: false,
              })
            }
          />
        )}
      </Suspense>
    </>
  );
}

export default Header;
