import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocalStorage } from "react-use";
import fetchJsonp from "fetch-jsonp";

const StateContext = createContext({
  posts: [],
  offset: 0,
  setOffset: () => {},
});

export const ContextProvider = ({ children }) => {
  const [offset, setOffset] = useState(0);
  const [posts, setPosts] = useLocalStorage("postlist_myspace");

  const fetchData = async () => {
    try {
      const response = await fetchJsonp(
        `https://api.vk.com/method/wall.get?owner_id=${
          process.env.GATSBY_OWNER_ID
        }&access_token=${process.env.GATSBY_VK_SERVICE_KEY}&extended=1&offset=${
          offset * 30
        }&count=${30}&v=${process.env.GATSBY_VK_API_VERSION}`
      );
      const json = await response.json();

      setPosts(json.response);
    } catch (error) {
      console.log("parsing failed", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [offset]);

  return (
    <StateContext.Provider
      value={{
        posts,
        offset,
        setOffset,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
