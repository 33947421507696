import React from "react";
import { ContextProvider } from "./context";

import Header from "./header";
import Footer from "./footer";

function Layout({ children }) {
  return (
    <ContextProvider>
      <Header />
      <main>{children}</main>
      <Footer />
    </ContextProvider>
  );
}

export default Layout;
