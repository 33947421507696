import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Logo from "../images/svg/logo";

import * as global from "../styles/global.module.scss";
import * as styles from "./footer.module.scss";

function Footer() {
  const data = useStaticQuery(graphql`
    query {
      allOffersJson {
        edges {
          node {
            name
            slug
          }
        }
      }
    }
  `);

  return (
    <footer className={styles.footer}>
      <div className={global.container}>
        <div className={styles.container}>
          <div className={styles.left}>
            <Link to="/" className={styles.link}>
              <p>Главная страница</p>
            </Link>
            {data.allOffersJson.edges.map((item, index) => {
              const { name, slug } = item.node;

              return (
                <Link
                  to={`/${slug}`}
                  className={styles.link}
                  key={`link_${index}`}
                >
                  <p>{name}</p>
                </Link>
              );
            })}
          </div>
          <div className={styles.center}>
            <Link to="/" aria-label="Логотип Мое пространство">
              <Logo className={styles.logo} />
            </Link>
            <p>
              г. Москва, пр. Серебрякова, 14, стр. 15
              <br /> Ботанический сад
            </p>
          </div>
          <div className={styles.right}>
            <p>Контакты:</p>
            <a href="tel:+74950854858" className={styles.link}>
              +7 (495) 085-48-58
            </a>
            <a href="mailto:info@moe-prostranstvo.ru" className={styles.link}>
              info@moe-prostranstvo.ru
            </a>
            <a
              href="https://vk.com/moepros"
              className={styles.link}
              id="link_vk"
              target="_blank"
              rel="noreferrer"
            >
              Группа ВКонтакте
            </a>
            <p className={styles.heycoddes}>
              Сайт создан командой{" "}
              <a
                href="https://www.heycoddes.ru"
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                Hey!Coddes
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
